import {useEffect, useRef, useState} from 'react';
import './App.css';
import './index.css';
import styled from 'styled-components';
import { styled as mstyled, ThemeProvider } from '@material-ui/styles';
import {Modal, createTheme} from '@material-ui/core';
import {Box, Button, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import domtoimage from 'dom-to-image';
import ReactGA from "react-ga";
import {logEvent} from '@firebase/analytics';
import {analytics} from '.';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const theme = createTheme({
    palette: {
        primary: {
            main: "#03C75A"
        },
        black1: {
            main: "#131313",
        },
        black2: {
            main: "#4A4A4A"
        },
        black3: {
            main: "#4A4A4A"
        },
        black4: {
            main: "#747474"
        },
        black5: {
            main: "#393939"
        },
        blue1: {
            main: "#00AAFF"
        },
        gary8: {
            main: "#B5B5B5"
        },
        gray1: {
            main: "#EAEAEA"
        },
        gray2: {
            main: "#B7B7B7"
        },
        gray3: {
            main: "#868688"
        },
        gray4: {
            main: "#7B7B7B"
        },
        gray5: {
            main: "#959595"
        },
        gray6: {
            main: "#F6F6F6"
        },
        gray7: {
            main: "#9F9F9F"
        },
        green1: {
            main: "#03C75A"
        },
        green2: {
            main: "#06D964"
        },
        orange1: {
            main: "#FF5500"
        },
        red1: {
            main: "#FF0000"
        },
        white1: {
            main: "#FFFFFF"
        },
    }
});
const OverlaySpinner = () => {
    return (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    );
};
const Spinner = () => {
    return (
        <div className="loader"></div>
    );
};
const Spinner2 = () => {
    return (
        <div className="loadingio-spinner-spinner-c0ad5ihg5qq"><div className="ldio-pe8mtn3dpyh">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
    );
};
const Text = styled.a`
    font-size: ${({size}) => size || 14}px;
    font-weight: ${({bold}) => bold ? "bold" : "normal"};
    color: ${({color}) => color || "var(--black1)"};
    font-family: "Apple SD Gothic Neo";
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`
const TextLine = styled.span`
`
const MyButton = mstyled(Button)({
    borderRadius: 10,
    textAlign: 'center',
    borderWidth: 0,
    boxShadow: 'none'
});
     
const MyTextField = mstyled(TextField)({
    padding: 0,
    '& .MuiInputBase-input': {
        padding: 12,
        position: 'relative',
        fontSize: 16,
        fontFamily: "Apple SD Gothic Neo",
        width: '100%',
            nsition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 10,
        paddingLeft: 16,
        paddingRight: 16,
        '& fieldset': {
          borderColor: theme.palette.black2,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.green1,
        },
      },
})

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100dvh;
    flex-direction: column;
    background-color: var(--white1);
`
const Body = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100dvh;
    flex-direction: column;
    overflow-y: scroll;
    background-color: var(--white1);
`
const Header = styled.div`
    position: sticky;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: 100%;
    flex-shrink: 0;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
const HeaderRight = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
`
const MainPanel = styled.div`
    padding-top: 64px;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 36px;
`
const MainPanelInputContainer = styled.div`
    width: 100%;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 57px;
`
const MainPanelInputButton = mstyled(Button)({
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 16,
    paddingRight: 16,
    borderColor: 'var(--black2)'
})

const SearchModalContainer = styled.div`
    max-width: 448px;
    width: 100%;
    height: 75%;
    padding-top: 24px;
    padding-bottom: 34px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--white1);
    border-radius: 10px;
`
const SearchModalTitle = styled.div`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
`
const SearchModalButtonContainer = styled.div`
    padding-top: 16px;
`
const EmptySearchResultPanel = styled.div`
    flex: 1;
    margin-top: 50px;
    align-items: center;
    row-gap: 6px;
`
const KeywordInput = styled.div`
    display: flex;
    margin-top: 10px;
    width: 100%;
    flex-direction: row;
    column-gap: 10px;
`
const SearchResultPanel = styled.div`
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
`
const Selector = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid var(--green1);
    align-items: center;
    justify-content: center;
`
const SelectorInner = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: var(--green1);
`

const ResultPanel = styled.div`
    width: 100%;
    padding-top: 36px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 36px; 
    align-items: flex-start;
    border-top: 16px solid var(--gray6);
    background-color: var(--white1);
`
const ResultPanelInputContainer = styled.div`
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24;
`

const ResultPanelKeywordAddTextField = mstyled(TextField)({
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
    padding: 0,
    '& .MuiInputBase-input': {
        padding: 13,
        position: 'relative',
        fontSize: 16,
        fontFamily: "Apple SD Gothic Neo",
        width: '100%',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 5,
        paddingLeft: 16,
        paddingRight: 16,
        '& fieldset': {
          borderColor: theme.palette.black2,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.green1,
        },
    },
});
const ResultPanelKeywordAddButton = styled.span`
    position: absolute;
    right: 6px;
    margin-bottom: 24px;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: var(--black2);
    border-color: var(--black2);
    cursor: pointer;
`
const ResultReviewPanel = styled.div`
    width: 100%;
    padding-top: 24px;
    align-items: center;
`   
const ResultKeywordPanel = styled.div`
    width: 100%;
`
const ResultKeywordListContainer = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 7px;
    row-gap: 11px;
    padding-top: 20px;
    padding-bottom: 27px;
`
const KeywordContainer = styled.a`
    align-items: center;
    column-gap: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 13px;
    padding-right: 13px;
    border: 1px solid var(--gray5);
    background-color: ${({selected}) => selected ? "var(--black2)" : "transparent"};
    border-radius: 10px;
    font-size: 12px;
    font-family: "Apple SD Gothic Neo";
    color: ${({selected}) => selected ? "var(--white1)" : "var(--gray5)"};
    font-weight: ${({selected}) => selected ? "bold" : "normal"};
    cursor: pointer;
`
const TagContainer = styled.a`
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border: ${({color}) => color ? `1px solid ${color}` : 'none'};
    color: ${({color}) => color || "var(--black1)"};
    border-radius: 5px;
    font-size: 12px;
    font-family: "Apple SD Gothic Neo";
    white-space: nowrap;
`
const ResultKeywordComparePanel = styled.div`
    padding-top: 36px;
    padding-bottom: 34px;
    border-top: 1px solid var(--gray2);
    width: 100%;
`
const KeywordCompareTableCell = mstyled(TableCell)({
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: 'none',
    width: '33%'
})
const KeywordCompareTableCategoryCell = mstyled(TableCell)({
    padding: 0,
    paddingTop: 16,
    borderBottom: 'none'
})
const removeTags = (text) => {
    return text.replace(/<[^>]*>/g, '');
}

const KeywordButton = ({selected = false, text = "", onClick = () => {}}) => {
    return (
        <KeywordContainer selected={selected} onClick={onClick}>
            {text}
        </KeywordContainer>
    )
}
const determinKeywordTagPlace = (mRank, oRank, totalQcCount) => {
    if(totalQcCount < 1000) {
        if(mRank < 0) {
            if(oRank < 0) {
                return 4;
            }
            else {
                return 2;
            }
        }
        else if(mRank < 10) {
            return 1;
        }
        else if(mRank < 32) {
            return 2;
        } 
    }
    else {
        if(mRank < 0) {
            return 3;
        }
        else if(mRank < 10) {
            return 1;
        }
        else if(mRank < 32) {
            if(oRank < 0) {
                return 3;
            }
            else if(oRank < 10) {
                return 2;
            } else if(oRank < 32) {
                return 3;
            }
        } 
    }
    return 0;
}
const determinKeywordTagBlog = (mRank, oRank, totalQcCount) => {
    if(totalQcCount < 1000) {
        if(mRank < 0 || mRank > 30) {
            if(oRank < 0 || oRank > 30) {
                return 4;
            }
            else {
                return 2;
            }
        }
        else if(mRank < 30) {
            return 1;
        }
    }
    else {
        if(mRank < 0 || mRank > 30) {
            if(oRank < 0 || oRank > 30) {
                return 3;
            }
            else {
                return 2;
            }
        }
        else if(mRank < 30) {
            return 1;
        }
        return 0;
    }
    return 0;
}
const TAG_COLOR_MAP = {
    0: 'var(--black1)',
    1: 'var(--green1)',
    2: 'var(--blue1)',
    3: 'var(--red1)',
    4: 'var(--orange1)',
}
const TAG_COMPONENT_MAP = {
    0: (<></>),
    1: (<TagContainer color="var(--green1)">
            잘 하고 있어요 🏆
        </TagContainer>),
    2: (<TagContainer color="var(--blue1)">
            도전해 봐요 ⚽
        </TagContainer>),
    3: (<TagContainer color="var(--red1)">
            너무 치열해요 🔥
        </TagContainer>),
    4: (<TagContainer color="var(--orange1)">
            중요하지 않아요 ⛔️
        </TagContainer>
        ),
}
const StartPage = ({
    restaurantSubmitted,
    restaurantSubmitted2,
    setRestaurantSubmitted,
    setRestaurantSubmitted2,
    setKeywordsSubmitted,
    setNsaData,
    handleShare,
    handleContact
}) => {
    const navigate = useNavigate();
    const [comparing, setComparing] = useState(false);
    const [searchMyModalVisible, setSearchMyModalVisible] = useState(false);
    const [searchOtherModalVisible, setSearchOtherModalVisible] = useState(false);
    const [restaurantInput, setRestaurantInput] = useState("");
    const [restaurantInput2, setRestaurantInput2] = useState("");
    const [restaurantSearchResult, setRestaurantSearchResult] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState({
        index: -1,
        name: "",
        category: "",
        address: "",
        roadAddress: "",
        mapx: 0,
        mapy: 0,
        blogCafeReviewCount: 0,
        visitorReviewCount: 0
    })
    const [restaurantSearchResult2, setRestaurantSearchResult2] = useState([]);
    const [selectedRestaurant2, setSelectedRestaurant2] = useState({
        index: -1,
        name: "",
        category: "",
        address: "",
        roadAddress: "",
        mapx: 0,
        mapy: 0,
        blogCafeReviewCount: 0,
        visitorReviewCount: 0
    })
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    const handleSearchMyModalOpen = () => {
        logEvent(analytics, 'on_press_fill_mystore_btn');
        // ReactGA.event({
        //     action: 'on_press_fill_mystore_btn',
        //     category: "engagement",
        // });
        setSearchMyModalVisible(true);
    }
    const handleSearchOtherModalOpen = () => {
        logEvent(analytics, 'on_press_fill_otherstore_btn');
        // ReactGA.event({
        //     action: 'on_press_fill_otherstore_btn',
        //     category: "engagement",
        // });
        setSearchOtherModalVisible(true);
    }
    const handleSearchMyModalClose = () => {
        setRestaurantSearchResult([])
        setSearchMyModalVisible(false);
    }
    const handleSearchOtherModalClose = () => {
        setRestaurantSearchResult2([])
        setSearchOtherModalVisible(false);
    }
    const cancelSearchMy = () => {
        setRestaurantInput("");
        setSelectedRestaurant({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        handleSearchMyModalClose();
    }
    const cancelSearchOther = () => {
        setRestaurantInput2("");
        setSelectedRestaurant2({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        handleSearchOtherModalClose();
    }
    const selectRestaurant = (index) => {
        const {title, category, address, roadAddress, mapx, mapy} = restaurantSearchResult[index];
        if(index === selectedRestaurant.index) {
            setSelectedRestaurant({
                index: -1,
                name: "",
                category: "",
                address: "",
                roadAddress: "",
                mapx: 0,
                mapy: 0,
                blogCafeReviewCount: 0,
                visitorReviewCount: 0
            });
        }
        else {
            setSelectedRestaurant({
                index,
                name: removeTags(title),
                category,
                address,
                roadAddress,
                mapx,
                mapy,
                blogCafeReviewCount: 0,
                visitorReviewCount: 0
            });
        }
        
    }
    const selectRestaurant2 = (index) => {
        const {title, category, address, roadAddress, mapx, mapy} = restaurantSearchResult2[index];
        if(index === selectedRestaurant2.index) {
            setSelectedRestaurant2({
                index: -1,
                name: "",
                category: "",
                address: "",
                roadAddress: "",
                mapx: 0,
                mapy: 0,
                blogCafeReviewCount: 0,
                visitorReviewCount: 0
            });
        }
        else {
            setSelectedRestaurant2({
                index,
                name: removeTags(title),
                category,
                address,
                roadAddress,
                mapx,
                mapy,
                blogCafeReviewCount: 0,
                visitorReviewCount: 0
            });
        }
        
    }
    const completeSearchMy = () => {
        logEvent(analytics, 'on_press_choose_mystore_btn', {
            store_name: selectedRestaurant.name
        });
        // ReactGA.event({
        //     action: 'on_press_choose_mystore_btn',
        //     category: "engagement",
        //     label: selectedRestaurant.name
        // });
        setRestaurantSubmitted(selectedRestaurant);
        handleSearchMyModalClose();
    }
    const completeSearchOther = () => {
        logEvent(analytics, 'on_press_choose_otherstore_btn', {
            store_name: selectedRestaurant2.name
        });
        // ReactGA.event({
        //     action: 'on_press_choose_otherstore_btn',
        //     category: "engagement",
        //     label: selectedRestaurant2.name
        // });
        setRestaurantSubmitted2(selectedRestaurant2)
        handleSearchOtherModalClose();
    }
    const requestCompare = async () => {
        if(comparing) return;
        logEvent(analytics, 'on_press_compare_main_btn');
        // ReactGA.event({
        //     action: 'on_press_compare_main_btn',
        //     category: "engagement",
        // });
        setComparing(true);
        try {
            const result = await requestGenerateKeywords();
            if(result) {
                const keywords = result.data.map((item) => {
                    return item.relKeyword;
                })
                setKeywordsSubmitted(keywords);
                setNsaData(result.data)
                const restaurantInfo = result.restaurantInfo;
                const restaurantInfo2 = result.restaurantInfo2;
                setRestaurantSubmitted({
                    ...restaurantSubmitted,
                    blogCafeReviewCount: restaurantInfo.blogCafeReviewCount,
                    visitorReviewCount: restaurantInfo.visitorReviewCount
                });
                setRestaurantSubmitted2({
                    ...restaurantSubmitted2,
                    blogCafeReviewCount: restaurantInfo2.blogCafeReviewCount,
                    visitorReviewCount: restaurantInfo2.visitorReviewCount
                })
            }
        } catch(err) {
            console.log(err);
        }
        
        setComparing(false);
        setSelectedRestaurant({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        setSelectedRestaurant2({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        setRestaurantInput("")
        setRestaurantInput2("")
        navigate("/result");
    }
    const handleSubmitRestaurant = async () => {
        if(restaurantInput === "") return;
        logEvent(analytics, 'on_press_search_mystore_btn');
        // ReactGA.event({
        //     action: 'on_press_search_mystore_btn',
        //     category: "engagement",
        // });
        setSelectedRestaurant({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        const result = await fetchNaverSearchPlace(restaurantInput);
        if(result.data)
            setRestaurantSearchResult(result.data);
    }
    const handleSubmitRestaurant2 = async () => {
        if(restaurantInput2 === "") return;
        logEvent(analytics, 'on_press_search_otherstore_btn');
        // ReactGA.event({
        //     action: 'on_press_search_otherstore_btn',
        //     category: "engagement",
        // });
        setSelectedRestaurant2({
            index: -1,
            name: "",
            category: "",
            address: "",
            roadAddress: "",
            mapx: 0,
            mapy: 0,
            blogCafeReviewCount: 0,
            visitorReviewCount: 0
        });
        const result = await fetchNaverSearchPlace(restaurantInput2);
        if(result.data)
            setRestaurantSearchResult2(result.data);
    }
    const fetchNaverSearchPlace = async (keyword) => {
        let parameterString = `keyword=${keyword}`
        // const URL = `http://127.0.0.1:5000/naver_search_place?${parameterString}`
        const URL = `${BASE_URL}/naver_search_place?${parameterString}`

        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if(response.status === 200) {
            return response.json()
        }
        else {
            return null;
        }
    }
    const requestGenerateKeywords = async () => {
        // const URL = `http://localhost:5000/generate_keywords`
        const URL = `${BASE_URL}/generate_keywords`

        const response = await fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                keyword: restaurantSubmitted.name,
                keyword2: restaurantSubmitted2.name,
                restaurant: restaurantSubmitted,
                restaurant2: restaurantSubmitted2,
            })
        });
        if(response.status === 200) {
            return response.json();
        }
        else {
            console.log(response.text)
        }
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Modal
                style={{
                    width: '100%',
                    height: '100%',
                    alignItems:'center',
                    justifyContent: 'center',
                    padding: 16
                }}
                open={searchMyModalVisible}
                >   
                    <SearchModalContainer>
                        <SearchModalTitle>
                            <Text size={24} bold>
                                가게 검색
                            </Text>
                            <CloseIcon style={{
                                color: 'var(--black1)',
                                width: 24,
                                height: 24,
                            }}
                            onClick={cancelSearchMy}
                            />
                        </SearchModalTitle>
                        <KeywordInput>
                            <MyTextField 
                            fullWidth
                            variant='outlined'
                            id="restaurant" 
                            placeholder='시/군/구 + 가게 이름'
                            value={restaurantInput} 
                            onChange={(event) => setRestaurantInput(event.target.value)} 
                            onKeyUp={(event) => {
                                if(restaurantSearchResult === "") return;
                                if(event.key === 'Enter') 
                                    handleSubmitRestaurant();
                                }}
                            />
                            <MyButton
                            color="primary"
                            variant='contained'
                            onClick={handleSubmitRestaurant}
                            disabled={restaurantInput===''}
                            >
                                <Text bold color="var(--white1)">
                                검색
                                </Text>
                            </MyButton>
                        </KeywordInput>
                        {restaurantSearchResult.length > 0 &&
                        <Text bold size={16} style={{
                            marginTop: 24,
                            marginBottom: 24,
                        }}>
                            검색 결과
                        </Text>
                        }
                        {restaurantSearchResult.length > 0 ?
                            <SearchResultPanel>
                                <Table>
                                    <TableBody>
                                        {restaurantSearchResult.map((item, index) => {
                                            return (<TableRow key={index}
                                                onClick={() => selectRestaurant(index)}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <TableCell style={{
                                                    borderBottom: '1px solid var(--gray2)'
                                                }}>
                                                    <Selector>
                                                        {selectedRestaurant.index === index &&
                                                        <SelectorInner/>
                                                        }
                                                    </Selector>
                                                </TableCell>
                                                <TableCell style={{
                                                    borderBottom: '1px solid var(--gray2)'
                                                }}>
                                                    {removeTags(item.title)}
                                                </TableCell>
                                                <TableCell style={{
                                                    borderBottom: '1px solid var(--gray2)'
                                                }}>
                                                    {item.roadAddress}
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </SearchResultPanel>
                        :
                            <EmptySearchResultPanel>
                                <Text color={'var(--black4)'} size={12}> 
                                        네이버 플레이스에 등록된 상호명을 입력하시면
                                </Text>
                                <Text color={'var(--black4)'} size={12}> 
                                        더 빠른 검색이 가능합니다.
                                </Text>
                            </EmptySearchResultPanel>
                        }
                        <SearchModalButtonContainer>
                        <MyButton 
                        fullWidth
                        variant="contained" color="primary"
                        onClick={completeSearchMy}
                        disabled={selectedRestaurant.index === -1}
                        >
                            <Text bold color="var(--white1)" size={20}>
                                선택 완료
                            </Text>
                        </MyButton>
                        </SearchModalButtonContainer>
                        
                    </SearchModalContainer>
                </Modal>
                <Modal
                style={{
                    width: '100%',
                    height: '100%',
                    alignItems:'center',
                    justifyContent: 'center',
                    padding: 16
                }}
                    open={searchOtherModalVisible}
                    >   
                    <SearchModalContainer>
                        <SearchModalTitle>
                            <Text size={24} bold>
                                가게 검색
                            </Text>
                            <CloseIcon style={{
                                color: 'var(--black1)',
                                width: 24,
                                height: 24,
                            }}
                            onClick={cancelSearchOther}
                            />
                        </SearchModalTitle>
                        <KeywordInput>
                            <MyTextField 
                            fullWidth
                            variant='outlined'
                            id="restaurant" 
                            placeholder='시/군/구 + 가게 이름'
                            value={restaurantInput2} 
                            onChange={(event) => setRestaurantInput2(event.target.value)} 
                            onKeyUp={(event) => {
                                if(restaurantSearchResult2 === "") return;
                                if(event.key === 'Enter') 
                                    handleSubmitRestaurant2();
                                }}/>
                            <MyButton
                            color="primary"
                            variant='contained'
                            onClick={handleSubmitRestaurant2}
                            disabled={restaurantInput2===''}
                            >
                                <Text bold color="var(--white1)">
                                검색
                                </Text>
                            </MyButton>
                        </KeywordInput>
                        {restaurantSearchResult2.length > 0 &&
                        <Text bold size={16} style={{
                            marginTop: 24,
                            marginBottom: 24,
                        }}>
                            검색 결과
                        </Text>
                        }
                        {restaurantSearchResult2.length > 0 ?
                            <SearchResultPanel>
                                <Table>
                                    <TableBody>
                                        {restaurantSearchResult2.map((item, index) => {
                                            return (<TableRow key={index}
                                                onClick={() => selectRestaurant2(index)}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <TableCell>
                                                    <Selector>
                                                        {selectedRestaurant2.index === index &&
                                                        <SelectorInner/>
                                                        }
                                                    </Selector>
                                                </TableCell>
                                                <TableCell>
                                                    {removeTags(item.title)}
                                                </TableCell>
                                                <TableCell>
                                                    {item.roadAddress}
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </SearchResultPanel>
                        :
                            <EmptySearchResultPanel>
                            <Text color={'var(--black4)'} size={12}> 
                                더 정확한 결과를 얻기 위해서
                                </Text>
                                <Text color={'var(--black4)'} size={12}> 
                                같은 상권의 동일 업종 가게 중
                                </Text>
                                <Text color={'var(--black4)'} size={12}> 
                                가장 유명하고 잘 되는 곳을 입력하세요.
                                </Text>
                            </EmptySearchResultPanel>
                        }
                        <SearchModalButtonContainer>
                        <MyButton 
                        fullWidth
                        variant="contained" color="primary"
                        onClick={completeSearchOther}
                        disabled={selectedRestaurant2.index === -1}
                        >
                            <Text bold color="var(--white1)" size={20}>
                                선택 완료
                            </Text>
                        </MyButton>
                        </SearchModalButtonContainer>
                        
                    </SearchModalContainer>
                </Modal>
                <Header>
                        <Text bold size={16} color='var(--black1)' href="https://mystore.ydub.kr/start">
                            네이버 VS검색
                        </Text>
                        <HeaderRight>
                        <Text bold size={12} color='var(--black1)'
                        onClick={handleContact}
                        style={{cursor:'pointer'}}
                        >
                            문의하기
                        </Text>
                        <Text bold size={12} color='var(--black1)'
                        onClick={handleShare}
                        style={{cursor:'pointer'}}
                        >
                            공유하기
                        </Text>
                        </HeaderRight>
                </Header>
                <Body>
                    <MainPanel>
                        <TextLine style={{marginBottom: 17}}>
                            <Text bold color='var(--black1)' size={24}>
                                우리 가게
                            </Text>
                            <Text bold color='var(--green1)' size={24}>
                                {" 네이버 VS검색"}
                            </Text>
                        </TextLine>
                        <Text bold color='var(--black1)' size={16}>
                            리뷰 수 / 키워드 순위
                        </Text>
                        <MainPanelInputContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '40%',
                                            padding: 0,
                                        }}>
                                            
                                            <MainPanelInputButton variant='outlined' fullWidth onClick={handleSearchMyModalOpen}>
                                                {restaurantSubmitted === null ?
                                                <Text size={14} color='var(--black4)'>
                                                내 가게 입력
                                                </Text>
                                                :
                                                <Text size={14} color='var(--black1)'>
                                                {restaurantSubmitted.name}
                                                </Text>
                                                }
                                            </MainPanelInputButton>
                                        </TableCell>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '20%',
                                            padding: 0,
                                        }}>
                                            <Text bold size={24} color='var(--green1)'>
                                                VS
                                            </Text>
                                        </TableCell> 
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '40%',
                                            padding: 0,
                                        }}>
                                            
                                            <MainPanelInputButton variant='outlined' fullWidth onClick={handleSearchOtherModalOpen}>
                                                {restaurantSubmitted2 === null ?
                                                <Text size={14} color='var(--black4)'>
                                                경쟁 가게 입력
                                                </Text>
                                                :
                                                <Text size={14} color='var(--black1)'>
                                                {restaurantSubmitted2.name}
                                                </Text>
                                                }
                                            </MainPanelInputButton>
                                        </TableCell> 
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </MainPanelInputContainer>
                        {comparing &&
                        <div style={{width:"100%", alignItems: 'center'}}>
                            <Spinner/>
                            <Text color='var(--black1)' size={12} style={{
                                marginTop: 24
                            }}>
                                비교 검색중입니다. 잠시만 기다려주세요.
                            </Text>
                        </div>
                        }
                        <div style={{width:"100%", alignItems: 'center'}}>
                            <Text color='var(--black1)' size={12}>
                                *내 가게와 경쟁 가게를 가장 빠르게 비교해보세요
                            </Text>
                            <MyButton variant='contained' color="primary" fullWidth style={{
                                marginTop: 12
                            }}
                            disabled={restaurantSubmitted === null || restaurantSubmitted2 === null}
                            onClick={requestCompare}
                            >
                                <SearchIcon style={{
                                    color: 'var(--white1)',
                                    marginRight: 6,
                                    width: 23,
                                    height: 23,
                                }}/>
                                <Text color='var(--white1)' size={20} bold>
                                    비교 검색하기
                                </Text>
                            </MyButton>
                        </div>
                    </MainPanel>
                    {!comparing&&
                    <ResultPanel>
                        <Text bold size={16}>
                            네이버 VS검색 결과
                        </Text>
                        <Text size={12} style={{marginTop: 4}}>
                            *이해를 돕기 위한 예시입니다. [가게 입력시 결과가 나와요]
                        </Text>
                        <ResultReviewPanel>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '40%',
                                        }}>
                                            <Text bold size={16}>
                                                신촌후라이드
                                            </Text>
                                        </TableCell>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '20%'
                                        }}>
                                            <Text bold size={24} color='var(--green1)'>
                                                VS
                                            </Text>
                                        </TableCell> 
                                        <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            width: '40%'
                                        }}>
                                            <Text bold size={16}>
                                                연세치킨
                                            </Text>
                                        </TableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none'
                                        }}>
                                            <Text size={16}>
                                                181
                                            </Text>
                                        </TableCell>
                                        <TableCell align='center' style={{
                                        borderBottom: 'none',
                                        padding: 0,
                                        }}>
                                            <Text size={12} bold>
                                                영수증 리뷰
                                            </Text>
                                        </TableCell> 
                                        <TableCell align='center' style={{
                                        borderBottom: 'none'
                                        }}>
                                            <Text size={16}>
                                                140
                                            </Text>
                                        </TableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='center' style={{
                                            borderBottom: 'none'
                                        }}>
                                            <Text size={16}>
                                                79
                                            </Text>
                                        </TableCell>
                                        <TableCell align='center' style={{
                                        borderBottom: 'none',
                                        padding: 0,
                                        }}>
                                            <Text size={12} bold>
                                                블로그 리뷰
                                            </Text>
                                        </TableCell> 
                                        <TableCell align='center' style={{
                                        borderBottom: 'none'
                                        }}>
                                            <Text size={16}>
                                                212
                                            </Text>
                                        </TableCell> 
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </ResultReviewPanel>
                        <ResultKeywordPanel style={{
                            paddingTop: 40,
                        }}>
                            <Text size={16} bold>
                                검색 키워드 별 순위 비교
                            </Text>
                            <Text size={12} style={{marginTop: 4}}>
                                *이해를 돕기 위한 예시입니다. [가게 입력시 결과가 나와요]
                            </Text>
                            <ResultKeywordListContainer>
                                <KeywordButton selected text="신촌 치킨"/>
                                <KeywordButton selected text="신촌 치킨집"/>
                                <KeywordButton selected text="신촌 닭강정"/>
                                <KeywordButton text="신촌역 치킨"/>
                                <KeywordButton text="신촌역 닭강정"/>
                                <KeywordButton text="신촌역 치킨집"/>
                                <KeywordButton text="신촌역 닭집"/>
                            </ResultKeywordListContainer>
                        </ResultKeywordPanel>
                        <ResultKeywordComparePanel>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                신촌후라이드
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <KeywordButton selected text="신촌 치킨"/>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                연세치킨
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCell align='center' style={{
                                            padding: 0,
                                            paddingTop: 4,
                                        }}>
                                            <Text size={12} color="var(--gray3)">
                                                월간 검색량 1,450
                                            </Text>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                플레이스
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--red1)" bold>
                                                32위밖
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--red1)">
                                                너무 치열해요 🔥
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                3위
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                블로그
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--blue1)" bold>
                                                50위 밖
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--blue1)">
                                                도전해 봐요 ⚽️
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                20위
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </ResultKeywordComparePanel>
                        <ResultKeywordComparePanel>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                신촌후라이드
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <KeywordButton selected text="신촌 치킨집"/>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                연세치킨
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCell align='center' style={{
                                            padding: 0,
                                            paddingTop: 4,
                                        }}>
                                            <Text size={12} color="var(--gray3)">
                                                월간 검색량 570
                                            </Text>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                플레이스
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--green2)" bold>
                                                5위
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--green2)">
                                                잘 하고 있어요 🏆
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                3위
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                블로그
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--green1)" bold>
                                                19위
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--green1)">
                                                잘 하고 있어요 🏆
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                15위
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </ResultKeywordComparePanel>
                        <ResultKeywordComparePanel>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                신촌후라이드
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <KeywordButton selected text="신촌 닭강정"/>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--gray4)">
                                                연세치킨
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCell align='center' style={{
                                            padding: 0,
                                            paddingTop: 4,
                                        }}>
                                            <Text size={12} color="var(--gray3)">
                                                월간 검색량 320
                                            </Text>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                플레이스
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--orange1)" bold>
                                                32위 밖
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--orange1)">
                                                중요하지 않아요 ⛔️
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                32위 밖
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'/>
                                        <KeywordCompareTableCategoryCell align='center'>
                                            <Text bold size={12}>
                                                블로그
                                            </Text>
                                        </KeywordCompareTableCategoryCell> 
                                        <KeywordCompareTableCell align='center'/>
                                    </TableRow>
                                    <TableRow>
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16} color="var(--orange1)" bold>
                                                32위 밖
                                            </Text>
                                        </KeywordCompareTableCell>
                                        <KeywordCompareTableCell align='center'>
                                            <TagContainer color="var(--orange1)">
                                                중요하지 않아요 ⛔️
                                            </TagContainer>
                                        </KeywordCompareTableCell> 
                                        <KeywordCompareTableCell align='center'>
                                            <Text size={16}>
                                                32위 밖
                                            </Text>
                                        </KeywordCompareTableCell> 
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </ResultKeywordComparePanel>
                    </ResultPanel>
                    }
                </Body>
            </Container>
        </ThemeProvider>
    )
}

const ResultPage = ({
    loading,
    setLoading,
    keywordsSubmitted,
    setKeywordsSubmitted,
    nsaData,
    setNsaData,
    restaurantSubmitted,
    restaurantSubmitted2,
    setRestaurantSubmitted,
    setRestaurantSubmitted2,
    handleShare,
    handleContact
}) => {
    const pageRef = useRef(null);
    const [userKeywordInput, setUserKeywordInput] = useState("");
    const [keywordsAdded, setKeywordsAdded] = useState([]);
    const [keywordsSelected, setkeywordsSelected] = useState([]);
    const [savingImage, setSavingImage] = useState(false);
    const [npData, setNpData] = useState({

    });
    const [nbData, setNbData] = useState({

    })
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    const navigate = useNavigate();
    useEffect(() => {
        if(keywordsSubmitted.length > 0 && keywordsSelected.length === 0) {
            const keyword = keywordsSubmitted[0];
            if(!(keyword in npData)) {
                fetchNaverPlace(keyword).then((result) => {
                    const newNpData = {...npData};
                    newNpData[keyword] = result;
                    setNpData(newNpData);
                });
            }
            if(!(keyword in nbData)) {
                fetchNaverBlog(keyword).then((result) => {
                    const newNbData = {...nbData};
                    newNbData[keyword] = result;
                    setNbData(newNbData);
                })
            }
            setkeywordsSelected([keyword])
        }
    }, [keywordsSubmitted])
    const handleReset = () => {
        logEvent(analytics, 'on_press_compare_again_btn');
        // ReactGA.event({
        //     action: 'on_press_compare_again_btn',
        //     category: "engagement",
        // });
        setKeywordsSubmitted([]);
        setkeywordsSelected([]);
        setKeywordsAdded([]);
        setUserKeywordInput("");
        setRestaurantSubmitted(null);
        setRestaurantSubmitted2(null);
        navigate("/start");
    }
    const fetchNaverSearchAd = async (keyword) => {
        const URL = `${BASE_URL}/naver_searchad?keyword=${keyword}`;
        // const URL = `http://localhost:5000/naver_searchad?keyword=${keyword}`

        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if(response.status === 200) {
            return response.json();
        }
        else {
            return null;
        }
    }
    const fetchNaverPlace = async (keyword) => {
        const URL = `${BASE_URL}/naver_place?keyword=${keyword}&restaurant=${restaurantSubmitted.name}&restaurant2=${restaurantSubmitted2.name}`
        // const URL = `http://localhost:5000/naver_place?keyword=${keyword}&restaurant=${restaurantSubmitted.name}&restaurant2=${restaurantSubmitted2.name}`

        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if(response.status === 200) {
            return response.json();
        }
        else {
            return null;
        }
    }
    const fetchNaverBlog = async (keyword) => {
        // const URL = `http://localhost:5000/naver_view?keyword=${keyword}&restaurant=${restaurantSubmitted.name}&restaurant2=${restaurantSubmitted2.name}`
        const URL = `${BASE_URL}/naver_view?keyword=${keyword}&restaurant=${restaurantSubmitted.name}&restaurant2=${restaurantSubmitted2.name}`
        const response = await fetch(URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if(response.status === 200) {
            return response.json()
        }
        else {
            return null;
        }
    }
    const handleAddKeyword = async () => {
        if(loading) return;
        if(userKeywordInput === "") return;
        logEvent(analytics, 'on_press_add_keyword_btn', {
            keyword_name: userKeywordInput
        });
        // ReactGA.event({
        //     action: 'on_press_add_keyword_btn',
        //     category: "engagement",
        //     label: userKeywordInput
        // });
        setLoading(true);
        const result = await fetchNaverSearchAd(userKeywordInput);
        if(result) {
            console.log(result.data);
            setNsaData((prev) => [...prev, ...result.data]);
            setKeywordsAdded((prev) => [...prev, userKeywordInput]);
            setUserKeywordInput("");
        }
        else {
            window.alert("오류가 발생했습니다.", "잠시 후 다시 시도해주세요.")
        }
        setLoading(false);
    }
    const selectKeyword = (keyword) => {
        if(keywordsSelected.findIndex((sel) => sel === keyword) >= 0) {
            setkeywordsSelected((prev) => prev.filter((item) => item !== keyword));
        }
        else {
            logEvent(analytics, 'on_press_lookup_keyword_btn', {
                keyword_name: keyword
            });
            // ReactGA.event({
            //     action: 'on_press_lookup_keyword_btn',
            //     category: "engagement",
            //     label: keyword
            // });
            setLoading(true);
            setkeywordsSelected((prev) => [...prev, keyword]);
            if(!(keyword in npData)) {
                fetchNaverPlace(keyword).then((result) => {
                    const newNpData = {...npData};
                    newNpData[keyword] = result;
                    setNpData(newNpData);
                });
            }
            if(!(keyword in nbData)) {
                fetchNaverBlog(keyword).then((result) => {
                    const newNbData = {...nbData};
                    newNbData[keyword] = result;
                    setNbData(newNbData);
                })
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000)
        }
    }
    const saveAsImageHandler = () => {
        logEvent(analytics, 'on_press_download_btn');
        // ReactGA.event({
        //     action: 'on_press_download_btn',
        //     category: "engagement",
        // });
        const node = pageRef.current; // 페이지의 DOM 요소를 선택
        node.style.overflowY = 'visible'
        setSavingImage(true);
        domtoimage.toPng(node) // DOM을 이미지로 변환
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'mystore-navervs.png'; // 이미지 파일 이름 설정
            link.href = dataUrl;
            link.click(); // 다운로드 링크 클릭
          })
          .catch((error) => {
            console.error('이미지 저장 실패:', error);
          }).finally(() => {
            node.style.overflowY = 'scroll'
            setSavingImage(false);
          });
    };
    const handleHowToRankUp = () => {
        logEvent(analytics, 'on_press_howto_rank_btn');
        // ReactGA.event({
        //     action: 'on_press_howto_rank_btn',
        //     category: "engagement",
        // });
        window.open('https://www.ydub.kr/mystore');
    }
    try {
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    {loading && <OverlaySpinner />}
                    <Header>
                        <Text bold size={16} color='var(--black1)' href="https://mystore.ydub.kr/start">
                            네이버 VS검색
                        </Text>
                        <HeaderRight>
                        <Text bold size={12} color='var(--black1)' 
                        onClick={handleContact}
                        style={{cursor:'pointer'}}
                        >
                            문의하기
                        </Text>
                        <Text bold size={12} color='var(--black1)'
                        onClick={handleShare}
                        style={{cursor:'pointer'}}
                        >
                            공유하기
                        </Text>
                        </HeaderRight>
                    </Header>
                    <Body ref={pageRef}>
                        <MainPanel>
                            <TextLine style={{marginBottom: 17}}>
                                <Text bold color='var(--black1)' size={24}>
                                    우리 가게
                                </Text>
                                <Text bold color='var(--green1)' size={24}>
                                    {" 네이버 VS검색"}
                                </Text>
                            </TextLine>
                            <Text bold color='var(--black1)' size={16}>
                                리뷰 수 / 키워드 순위
                            </Text>
                            <ResultReviewPanel>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center' style={{
                                                borderBottom: 'none',
                                                width: '40%',
                                            }}>
                                                <Text bold size={16}>
                                                    { restaurantSubmitted.name}
                                                </Text>
                                            </TableCell>
                                            <TableCell align='center' style={{
                                                borderBottom: 'none',
                                                width: '20%',
                                            }}>
                                                <Text bold size={24} color='var(--green1)'>
                                                    VS
                                                </Text>
                                            </TableCell> 
                                            <TableCell align='center' style={{
                                                borderBottom: 'none',
                                                width: '40%',
                                            }}>
                                                <Text bold size={16}>
                                                    {restaurantSubmitted2.name}
                                                </Text>
                                            </TableCell> 
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='center' style={{
                                                borderBottom: 'none',
                                            }}>
                                                <Text size={16}>
                                                    {restaurantSubmitted.visitorReviewCount.toLocaleString('ko-kr')}
                                                </Text>
                                            </TableCell>
                                            <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            padding: 0
                                            }}>
                                                <Text size={12} bold>
                                                    영수증 리뷰
                                                </Text>
                                            </TableCell> 
                                            <TableCell align='center' style={{
                                            borderBottom: 'none'
                                            }}>
                                                <Text size={16}>
                                                    {restaurantSubmitted2.visitorReviewCount.toLocaleString('ko-kr')}
                                                </Text>
                                            </TableCell> 
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='center' style={{
                                                borderBottom: 'none'
                                            }}>
                                                <Text size={16}>
                                                    {restaurantSubmitted.blogCafeReviewCount.toLocaleString('ko-kr')}
                                                </Text>
                                            </TableCell>
                                            <TableCell align='center' style={{
                                            borderBottom: 'none',
                                            padding: 0
                                            }}>
                                                <Text size={12} bold>
                                                    블로그 리뷰
                                                </Text>
                                            </TableCell> 
                                            <TableCell align='center' style={{
                                            borderBottom: 'none'
                                            }}>
                                                <Text size={16}>
                                                    {restaurantSubmitted2.blogCafeReviewCount.toLocaleString('ko-kr')}
                                                </Text>
                                            </TableCell> 
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </ResultReviewPanel>
                            {
                            !savingImage &&
                            <div style={{width:"100%", alignItems: 'center'}}>
                                <MyButton variant='outlined' color="primary" fullWidth style={{
                                    marginTop: 12,
                                    border: '1px solid var(--green1)'
                                }}
                                onClick={handleReset}
                                >
                                    <Text color='var(--green1)' size={20} bold>
                                        다시 검색하기
                                    </Text>
                                </MyButton>
                            </div>
                            }
                        </MainPanel>
                        {keywordsSubmitted.length > 0 &&
                        <ResultPanel>
                            <ResultKeywordPanel>
                                <Text size={16} bold>
                                    검색 키워드 별 순위 비교
                                </Text>
                                <ResultKeywordListContainer>
                                    {keywordsSubmitted.map((keyword, index) => (
                                    <KeywordButton key={index} selected={keywordsSelected.findIndex((sel) => sel === keyword) >= 0} text={keyword}
                                    onClick={() => selectKeyword(keyword)}
                                    />
                                    ))}
                                    {keywordsAdded.map((keyword, index) => (
                                    <KeywordButton key={index + keywordsSubmitted.length} selected={keywordsSelected.findIndex((sel) => sel === keyword) >= 0} text={keyword}
                                    onClick={() => selectKeyword(keyword)}
                                    />
                                    ))}
                                </ResultKeywordListContainer>
                            </ResultKeywordPanel>
                            {!savingImage &&
                            <ResultPanelInputContainer>
                                <ResultPanelKeywordAddTextField
                                variant='outlined'
                                fullWidth
                                placeholder="상권 + 키워드 입력하기"
                                value={userKeywordInput}
                                onChange={(event) => setUserKeywordInput(event.target.value)} 
                                onKeyUp={(event) => {
                                    if(event.key === 'Enter') 
                                        handleAddKeyword();
                                    }} 
                                />
                                <ResultPanelKeywordAddButton onClick={handleAddKeyword}>
                                    <Text size={14} color="var(--white1)">
                                        추가
                                    </Text>
                                </ResultPanelKeywordAddButton>
                            </ResultPanelInputContainer>
                            }
                            {keywordsSelected.map((keyword, index) => {
                                const nsa = nsaData.find((item) => item.relKeyword === keyword.replace(" ", ""));
                                if(nsa && nbData[keyword] && npData[keyword]) {
                                    return (
                                        <ResultKeywordComparePanel key={index}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16} color="var(--gray4)">
                                                                {restaurantSubmitted.name}
                                                            </Text>
                                                        </KeywordCompareTableCell>
                                                        <KeywordCompareTableCell align='center'>
                                                            <KeywordButton selected text={keyword}/>
                                                        </KeywordCompareTableCell> 
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16} color="var(--gray4)">
                                                                {restaurantSubmitted2.name}
                                                            </Text>
                                                        </KeywordCompareTableCell> 
                                                    </TableRow>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'/>
                                                        <KeywordCompareTableCell align='center' style={{
                                                            padding: 0,
                                                            paddingTop: 4,
                                                        }}>
                                                            <Text size={12} color="var(--gray3)">
                                                                월간 검색량 {
                                                                    nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt > 10 ?
                                                                    (nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt).toLocaleString('ko-kr')
                                                                    : " <10"
                                                                }
                                                            </Text>
                                                        </KeywordCompareTableCell> 
                                                        <KeywordCompareTableCell align='center'/>
                                                    </TableRow>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'/>
                                                        <KeywordCompareTableCategoryCell align='center'>
                                                            <Text bold size={12}>
                                                                플레이스
                                                            </Text>
                                                        </KeywordCompareTableCategoryCell> 
                                                        <KeywordCompareTableCell align='center'/>
                                                    </TableRow>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16} color={TAG_COLOR_MAP[determinKeywordTagPlace(npData[keyword].order, npData[keyword].order2, nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt)]} bold>
                                                                {npData[keyword].order > -1 ?
                                                                `${npData[keyword].order + 1}위`
                                                                :
                                                                '32위 밖'
                                                                }
                                                            </Text>
                                                        </KeywordCompareTableCell>
                                                        <KeywordCompareTableCell align='center'>
                                                            {TAG_COMPONENT_MAP[determinKeywordTagPlace(npData[keyword].order, npData[keyword].order2, nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt)]}
                                                        </KeywordCompareTableCell> 
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16}>
                                                                {npData[keyword].order2 > -1 ?
                                                                `${npData[keyword].order2 + 1}위`
                                                                :
                                                                '32위 밖'
                                                                }
                                                            </Text>
                                                        </KeywordCompareTableCell> 
                                                    </TableRow>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'/>
                                                        <KeywordCompareTableCategoryCell align='center'>
                                                            <Text bold size={12}>
                                                                블로그
                                                            </Text>
                                                        </KeywordCompareTableCategoryCell> 
                                                        <KeywordCompareTableCell align='center'/>
                                                    </TableRow>
                                                    <TableRow>
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16}  color={TAG_COLOR_MAP[determinKeywordTagBlog(nbData[keyword].order, nbData[keyword].order2, nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt)]} bold>
                                                                {nbData[keyword].order > -1 ?
                                                                `${nbData[keyword].order + 1}위`
                                                                :
                                                                '100위 밖'
                                                                }
                                                            </Text>
                                                        </KeywordCompareTableCell>
                                                        <KeywordCompareTableCell align='center'>
                                                            {TAG_COMPONENT_MAP[determinKeywordTagBlog(nbData[keyword].order, nbData[keyword].order2, nsa.monthlyPcQcCnt + nsa.monthlyMobileQcCnt)]}
                                                        </KeywordCompareTableCell> 
                                                        <KeywordCompareTableCell align='center'>
                                                            <Text size={16}>
                                                                {
                                                                nbData[keyword].order2 > -1 ?
                                                                `${nbData[keyword].order2 + 1}위`
                                                                :
                                                                '100위 밖'
                                                                }
                                                            </Text>
                                                        </KeywordCompareTableCell> 
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {!savingImage&&
                                            <Text color="var(--blue1)" size={12} style={{
                                                textDecoration: 'underline',
                                                marginTop: 32,
                                                alignSelf: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleHowToRankUp}
                                            >
                                                네이버 마케팅, 사장님도 직접 할 수 있어요
                                            </Text>
                                            }
                                        </ResultKeywordComparePanel>
                                    )
                                } else {
                                    return(
                                        <ResultKeywordComparePanel>
                                            <div style={{width:"100%", alignItems: 'center'}}>
                                                <Spinner2/>
                                                <Text color='var(--gray2)' size={12} style={{
                                                    marginTop: 12
                                                }}>
                                                    데이터를 불러오는 중...
                                                </Text>
                                            </div>
                                        </ResultKeywordComparePanel>
                                        
                                    )
                                }
                            })}
                            {!savingImage &&
                            <MyButton color="primary" variant="contained" fullWidth
                            style={{
                                marginTop: 16
                            }}
                            onClick={saveAsImageHandler}
                            >
                                <Text color="var(--white1)" bold size={20}>
                                    페이지 저장하기
                                </Text>
                            </MyButton>
                            }
                        </ResultPanel>
                        }
                    </Body>
                </Container>
            </ThemeProvider>
        )
    } catch(err) {
        return <Navigate to={"/start"} />
    }
}
const App = () => {
    const [loading, setLoading] = useState(false);
    const [keywordsSubmitted, setKeywordsSubmitted] = useState([]);
    const [restaurantSubmitted, setRestaurantSubmitted] = useState(null);
    const [restaurantSubmitted2, setRestaurantSubmitted2] = useState(null);
    const [nsaData, setNsaData] = useState([]);
    const handleShare = async () => {
        logEvent(analytics, 'on_press_share_btn');
        // ReactGA.event({
        //     action: 'on_press_share_btn',
        //     category: "engagement",
        // });
        try {
          if (navigator.share) {
            await navigator.share({
              title: '우리 가게 네이버 VS검색',
              text: '네이버 순위를 올리고 싶으시면?',
              url: window.location.href
            });
          } else {
            // 공유 기능이 지원되지 않는 경우 대체 동작을 수행하거나 사용자에게 메시지 표시
            if(navigator.clipboard) {
                navigator.clipboard.writeText(window.location.href);
                alert('클립보드에 주소가 복사되었습니다.');    
            } else {
                alert('이 브라우저에서는 공유 기능을 지원하지 않습니다.');
            }
          }
        } catch (error) {
            console.error('공유 동작 실패:', error);
        }
    };
    const handleContact = () => {
        logEvent(analytics, 'on_press_contact_btn');
        // ReactGA.event({
        //     action: 'on_press_contact_btn',
        //     category: "engagement",
        // });
        window.open('https://www.ydub.kr/mystore');
    }
    return (
        <Routes>
            <Route path={"/"} 
            element={
            <StartPage 
            loading={loading}
            restaurantSubmitted={restaurantSubmitted} 
            restaurantSubmitted2={restaurantSubmitted2}
            setRestaurantSubmitted={setRestaurantSubmitted}
            setRestaurantSubmitted2={setRestaurantSubmitted2}
            setKeywordsSubmitted={setKeywordsSubmitted}
            setNsaData={setNsaData}
            handleShare={handleShare}
            handleContact={handleContact}
            />}/>
            
            <Route path={"start"} 
            element={
            <StartPage 
            loading={loading}
            restaurantSubmitted={restaurantSubmitted} 
            restaurantSubmitted2={restaurantSubmitted2}
            setRestaurantSubmitted={setRestaurantSubmitted}
            setRestaurantSubmitted2={setRestaurantSubmitted2}
            setKeywordsSubmitted={setKeywordsSubmitted}
            setNsaData={setNsaData}
            handleShare={handleShare}
            handleContact={handleContact}
            />}/>
            
            <Route path={"result"}
            element={
            <ResultPage
            loading={loading}
            setLoading={setLoading}
            keywordsSubmitted={keywordsSubmitted}
            setKeywordsSubmitted={setKeywordsSubmitted}
            nsaData={nsaData}
            setNsaData={setNsaData}
            restaurantSubmitted={restaurantSubmitted} 
            restaurantSubmitted2={restaurantSubmitted2}
            setRestaurantSubmitted={setRestaurantSubmitted}
            setRestaurantSubmitted2={setRestaurantSubmitted2}
            handleShare={handleShare}
            handleContact={handleContact}
            />
            }/>
        </Routes>

    );
}


export default App;
